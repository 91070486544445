import React from "react"
import imgPortadaGuide from "@components/pageGuide/guides/guide40/image/imgPortadaGuia.png"
import img1 from "@components/pageGuide/guides/guide40/image/img1.png"
import slider1 from "@components/pageGuide/guides/guide40/image/silder1.png"
import slider2 from "@components/pageGuide/guides/guide40/image/silder2.png"
import slider3 from "@components/pageGuide/guides/guide40/image/silder3.png"

const title = (
  <>
    <h1 className="titles-underlined-ebook ebook-ventas">
      Cómo aplicar la estrategia del océano azul
    </h1>
  </>
)

const textDescription = (
  <div>
    <p className="text-h2-ebook guide__description">
      La Estrategia del Océano Azul es un enfoque que se centra en identificar y
      resolver nuevos problemas con tu producto, permitiéndote superar a la
      competencia y dominar un mercado recién creado.
    </p>
  </div>
)

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"
    >
      Política de Privacidad.{" "}
    </a>
  </span>
)

const textOne = (
  <p>
    Para desarrollar una estrategia empresarial efectiva, es esencial comprender
    la Estrategia del Océano Azul, así como sus marcos clave. Este método
    implica la creación de un espacio de mercado inexplorado que puedes
    controlar y donde tu producto puede sobresalir.
    <br />
    <br />
    La clave reside en ofrecer soluciones a problemas aún no abordados,
    permitiéndote destacarte en un terreno donde la competencia es limitada.
  </p>
)

const data = {
  start: {
    support: "Guía | Telemarketing",
    title: title,
    description: textDescription,
    classtext: "guide__description-container",
    link: linkForm,
    href: "/ebook-8.pdf",
    brochure: "Guía",
    img: imgPortadaGuide,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Análisis de situación actual.",
      },
      {
        key: 2,
        text: "  Cómo identificar nuevas oportunidades.",
      },
      {
        key: 3,
        text: "Creación de nuevas ofertas y productos.",
      },
      {
        key: 4,
        text: "Lanzamiento de nuevos productos y/o servicios.",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },

  description: {
    cards: [
      {
        text: textOne,
        photo: img1,
        class: true,
      },
    ],
  },
}

export default data
